import React from "react"
import styled from "styled-components"
import media from "styled-media-query"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope, faHome, faPhone } from "@fortawesome/free-solid-svg-icons"

import SEO from "../components/SEO"
import Layout from "../components/layout/layout"
import { Container } from "../components/utils/utils"

const KontaktBaner = styled(BackgroundImage)`
  background-color: #659bad;
  height: auto;
  text-align: left;

  & > div {
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 40%,
      rgba(0, 136, 206, 0.7) 100%
    );
    width: 100%;
    min-height: 40vh;
    display: flex;
    align-items: center;
    padding-top: 10rem;
    padding-bottom: 6rem;
  }

  h1,
  h2 {
    color: rgb(87, 87, 87);
    font-weight: 900;
  }
`

const KontaktWrapper = styled.div`
  display: grid;
  padding-bottom: 5vh;
  margin: 10vh 0 15vh 0;

  ${media.lessThan("medium")`
  grid-template-columns: repeat(1, auto);
  margin: 0;
  `}

  ${media.greaterThan("medium")`
  grid-template-columns: repeat(3, 1fr);
  `}

  & > div {
    justify-self: center;
    margin-top: 5vh;

    ${media.lessThan("medium")`
    justify-self: left;
    `}
  }

  h6 {
    color: rgb(87, 87, 87);
    text-transform: uppercase;
    margin-bottom: 2rem;
    margin-top: 3rem;
    font-size: 16px;

    ${media.lessThan("medium")`
    margin-top: 1rem;
    `}
  }

  h3 {
    color: rgb(00, 136, 206);
  }

  hr {
    color: rgb(87, 87, 87);
    background-color: rgb(87, 87, 87);
    margin: 0.2vw 0;
    width: 50px;
    margin-left: 0;
    height: 3px;
  }
`

const KontaktItem = styled.div`
  display: flex;
  margin-top: 1rem;
  justify-content: start;

  && div:nth-child(1) {
    width: 4.3rem;
  }

  p {
    word-break: break-all;
  }

  + div {
    margin-top: 1rem;
  }
`

const Kontakt = () => {
  const data = useStaticQuery(graphql`
    query {
      bg: file(relativePath: { eq: "main_image.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1000) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  const main_image = data.bg.childImageSharp.fluid

  return (
    <div>
      <SEO title="Kontakt" slug="/kontakt" />
      <Layout>
        <KontaktBaner fluid={main_image} backgroundColor={`#FFFFFF`}>
          <div>
            <Container>
              <h1>Skontaktuj się z nami!</h1>
              <hr />
              <h2>
                Chętnie odpowiemy na Twoje pytania
                <br />i rozwiejemy wątpliwości.
                <br />
                Możemy zostać Twoim dostawcą!
              </h2>
            </Container>
          </div>
        </KontaktBaner>

        <Container>
          <KontaktWrapper>
            <div>
              <h3>Dane teleadresowe</h3>
              <h6>
                Hurtownia Stomatologiczna
                <br />
                Infinity Medical
              </h6>
              <KontaktItem>
                <div>
                  <FontAwesomeIcon icon={faHome} />
                </div>
                <div>
                  <p>ul. Skowronków 67</p>
                  <p>43-155 Bieruń</p>
                </div>
              </KontaktItem>
              <KontaktItem>
                <div>
                  <FontAwesomeIcon icon={faPhone} />
                </div>
                <div>
                  <p>+48 570 133 366</p>
                  <p>+48 883 670 370 (faktury)</p>
                </div>
              </KontaktItem>
              <KontaktItem>
                <div>
                  <FontAwesomeIcon icon={faEnvelope} />
                </div>
                <div>
                  <p>hurtowniainfinitymedical@gmail.com </p>
                </div>
              </KontaktItem>
            </div>

            <div>
              <h3>Godziny otwarcia</h3>
              <h6>
                Zapraszamy do kontaktu
                <br />
                telefonicznego!
              </h6>
              <KontaktItem>
                <div>
                  <b>PN</b>
                </div>
                <div>
                  <p>8:00 - 16:00</p>
                </div>
              </KontaktItem>
              <KontaktItem>
                <div>
                  <b>WT</b>
                </div>
                <div>
                  <p>8:00 - 16:00</p>
                </div>
              </KontaktItem>
              <KontaktItem>
                <div>
                  <b>ŚR</b>
                </div>
                <div>
                  <p>8:00 - 16:00</p>
                </div>
              </KontaktItem>
              <KontaktItem>
                <div>
                  <b>CZ</b>
                </div>
                <div>
                  <p>8:00 - 16:00</p>
                </div>
              </KontaktItem>
              <KontaktItem>
                <div>
                  <b>PT</b>
                </div>
                <div>
                  <p>8:00 - 13:00</p>
                </div>
              </KontaktItem>
            </div>

            <div>
              <h3>Dane fakturowe</h3>
              <h6>
                Infinity Medical
                <br />
                Artur Olszewski
              </h6>
              <KontaktItem>
                <div>
                  <FontAwesomeIcon icon={faHome} />
                </div>
                <div>
                  <p>ul. Skowronków 67</p>
                  <p>43-155 Bieruń</p>
                </div>
              </KontaktItem>
              <KontaktItem>
                <div>
                  <b>NIP</b>
                </div>
                <div>
                  <p>549 233 22 93</p>
                </div>
              </KontaktItem>
              <KontaktItem>
                <div>
                  <b>REGON</b>
                </div>
                <div>
                  <p>523809478</p>
                </div>
              </KontaktItem>
              <div>
                <p>
                  <b>NR KONTA ING BANK ŚLĄSKI</b>
                  <br />
                  28 1020 2384 0000 9702 0678 1217
                </p>
              </div>
            </div>
          </KontaktWrapper>
        </Container>
      </Layout>
    </div>
  )
}

export default Kontakt
